//@flow
import * as React from 'react';
import { BootstrapTable } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import './Datatable.css';
import './CustomDatatable.css';
import type { Options } from '../../services/defaultService';

type Props = {
  children: any,
  defaultOrder: string,
  defaultDirection: string,
  classSignal: string,
  service: any,
  filter: any,
  onRowClick: any,
  submitFilter: boolean,
};

type State = {
  data: Array<any>,
  dataTotalSize: number,
  currentPage: number,
  order: string,
  direction: string,
};

class Datatable extends React.PureComponent<Props, State> {
  static defaultProps = {
    loadOnInit: true,
  };
  constructor(props: Props) {
    super(props);

    let data = [];
    let dataTotalSize = 0;
    let currentPage =
      this.props.currentPage && this.props.controller == this.props.page
        ? this.props.currentPage
        : 1;

    this.state = {
      data,
      dataTotalSize,
      currentPage,
      order: this.props.defaultOrder,
      direction: this.props.defaultDirection
        ? this.props.defaultDirection
        : 'asc',
    };
  }

  componentDidMount() {
    this.props.changeTitle('0');
    if (this.props.loadOnInit) {
      this.props.loading(true);
      this.resetTable();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.filter && nextProps.submitFilter) {
      this.resetTable(nextProps.filter);
    }
  }

  resetTable = (filter?: any) => {
    this.requestData(
      {
        pageSize: 10,
        page: 1,
        order: this.state.order,
        direction: this.state.direction,
      },
      filter
    );
  };

  //Usado para atualizar a tabela de fora do componente.
  updateTable = () => {
    return this.requestData({
      pageSize: 10,
      page: this.state.currentPage,
      order: this.state.order,
      direction: this.state.direction,
    });
  };

  requestData = (options: Options, filter?: any) => {
    this.props.loading(true);
    let service = this.props.service.findByFiltro;
    if (this.props.noFindByFilter) {
      service = this.props.service;
    }

    return service(
      {
        pageSize: options.pageSize,
        page: options.page,
        order: options.order,
        direction: options.direction,
      },
      filter ? filter : this.props.filter
    )
      .then((response) => {
        this.props.changeFilter(
          filter ? filter : this.props.filter,
          this.props.controller,
          options.page
        );
        let data = response.data;
        this.setState({
          data: data.data,
          dataTotalSize: data.dataTotalSize,
          currentPage: options.page,
          order: options.order,
          direction: options.direction,
        });
        this.props.loading(false);
        this.props.changeTitle(`${data.dataTotalSize}`);
        if (this.props.onDataUpdate) {
          this.props.onDataUpdate(data.data);
        }
        return data.data;
      })
      .catch((error) => {
        console.log(error);
        this.props.loading(false);
      });
  };

  handlePageChange = (page: number) => {
    this.setState({
      currentPage: page,
    });

    this.requestData({
      pageSize: 10,
      page: page,
      order: this.state.order,
      direction: this.state.direction,
    });
  };

  handleSortChange = (sortName: string, sortOrder: string) => {
    this.setState({
      order: sortName,
      direction: sortOrder,
    });
    this.requestData({
      pageSize: 10,
      page: this.state.currentPage,
      order: sortName,
      direction: sortOrder,
    });
  };

  renderShowsTotal = (start: number, to: number, total: number) => {
    return (
      <p className={'total-text small'}>
        Exibindo {to} de {total} registros
      </p>
    );
  };

  render() {
    return (
      <BootstrapTable
        version="4"
        data={this.state.data}
        remote={true}
        pagination={true}
        search={false}
        multiColumnSearch={false}
        fetchInfo={{ dataTotalSize: this.state.dataTotalSize }}
        insertRow={false}
        deleteRow={false}
        exportCSV={false}
        striped={false}
        bordered={false}
        headerContainerClass="table-header"
        tableContainerClass={`table-container-class ${
          this.props.onRowClick ? 'action' : ''
        } ${this.props.classSignal}`}
        options={{
          sizePerPage: 10,
          sizePerPageList: [10, 20, 50, 100],
          page: this.state.currentPage,
          onPageChange: this.handlePageChange,
          onSortChange: this.handleSortChange,
          noDataText: 'Nenhuma informação encontrada.',
          paginationShowsTotal: this.renderShowsTotal,
          prePage: 'Anterior', // Previous page button text
          nextPage: 'Próximo', // Next page button text
          firstPage: 'Primeiro', // First page button text
          lastPage: 'Último', // Last page button text
          prePageTitle: 'Anterior', // Previous page button title
          nextPageTitle: 'Próximo', // Next page button title
          firstPageTitle: 'Primeiro', // First page button title
          lastPageTitle: 'Último',
          alwaysShowAllBtns: true,
          withFirstAndLast: true,
          paginationSize: 3,
          hideSizePerPage: true,
          sortIndicator: true,
          onRowClick: this.props.onRowClick,
        }}
      >
        {this.props.children}
      </BootstrapTable>
    );
  }
}
function mapStateToProps(state) {
  const { page, currentPage } = state.defaultFilter;

  return {
    page,
    currentPage,
  };
}
const mapDispatch = ({
  load: { loading },
  title: { changeTitle },
  defaultFilter: { changeFilter },
}) => ({
  loading: (load: boolean) => loading({ load }),
  changeTitle: (subTitle: string) => changeTitle({ subTitle }),
  changeFilter: (filter, page, currentPage) =>
    changeFilter({ filter, page, currentPage }),
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(Datatable);
